import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
// This import statement has to stay, else the scss isn't included
// noinspection ES6UnusedImports
import styles from "./language.module.scss"
import PostList from "../../components/post_list/post_list"
import Text from "../../components/text/text"
const format = require("string-format")

class LanguageTemplate extends React.Component {
  render() {
    const {
      language,
      posts,
    } = this.props.pageContext
    const lang = language.frontmatter.lang
    const general_strings = require("./../../../content/" + lang + "/strings.json")
    const implementation_strings = require("./../../../content/" + lang + "/implementations/strings.json")
    const title = format(implementation_strings.all_algorithms, language.frontmatter.name)
    return (
      <Layout lang={language.frontmatter.lang}>
        <SEO
          title={title}
          description={language.excerpt}
          lang={language.frontmatter.lang}
        />

        <article>
          <header>
            <h1>
              {title}
            </h1>
          </header>

          <section className="row">
            <div className="col-lg-12">
              {posts ? <PostList posts={posts} useExcerptFrom={'algorithm'}/> : `No algorithms implemented in ${language.frontmatter.name} yet.`}
            </div>
          </section>

          <section className={"row"}>
            <div className="col-lg-12">
              <h2>{implementation_strings.about_language}</h2>
            </div>
            <div className="col-lg-12">
              <Text html={language.html} title={language.frontmatter.title}/>
            </div>
          </section>
        </article>
      </Layout>
    )
  }
}

export default LanguageTemplate
