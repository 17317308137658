import React from "react"
// This import statement has to stay, else the scss isn't included
// noinspection ES6UnusedImports
import styles from "./post_list.module.scss"
import _ from "lodash"
import Moment from "react-moment"

// TODO revisit template once we have images

class PostList extends React.Component {
  render() {
    const { posts, useExcerptFrom } = this.props
    const lang = posts[0].algorithm.frontmatter.lang
    const general_strings = require("./../../../content/" + lang + "/strings.json")
    const implementation_strings = require("./../../../content/" + lang + "/implementations/strings.json")
    return (
      <ul>
        {_.map(posts, post => {
            const path = "/" + (lang !== "en" ? lang + "/" : "") + post.algorithm.frontmatter.slug + "/" + post.language.frontmatter.slug
            return <div key={path} className="mt-4">
              <h4 className="mb-3">
                {post.algorithm.frontmatter.name + " in " + post.language.frontmatter.name}
              </h4>
              <p>
                {general_strings.posted + ": "}
                <i><Moment format="YYYY-MM-DD HH:mm" date={post.code.created_at}/></i>
                {", " + general_strings.last_updated + ": "}
                <i><Moment format="YYYY-MM-DD HH:mm" date={post.code.updated_at}/></i>
                {/* TODO by User XYZ */}
              </p>
              {useExcerptFrom ? <p>{post[useExcerptFrom].excerpt}</p> : ""}
              <a href={path} className="btn btn-primary btn-md">
                {implementation_strings.link}
              </a>
              <hr className="mt-4"/>
            </div>
          },
        )}
      </ul>
    )
  }
}


export default PostList
